<template>
  <div class="jd-city-wrap">
    <van-popup v-model:show="show"
               position="bottom"
               round
               overlay-class="city-popup"
               closeable>
      <div>
        <div class="line-height-16 text-center c-333 fs16 pt20 pb20">
          <span>所在地区</span>
        </div>
        <div class="flex text-left fs14 line-height-14 c-333 pb20 pl15 pr15">
          <div class="flex text-left">
            <div class="mr30 city-name-box pr"
                 @click="changeAddress(item, index,item.pid)"
                 v-for="(item, index) in selectAddressNameList"
                 :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="pr"
               :class="{ 'select-text': !ifClickCityName }"
               v-if="selectAddressNameList.length != 4">
            请选择
          </div>
        </div>
        <div class="city-list-box fs14 line-height-14 c-333 jd-border-top">
          <div v-if="addressList.length">
            <div class="pt10 pb10 city-item-box"
                 v-for="(item, index) in addressList"
                 :key="index"
                 @click="selectCity(item, index)">
              {{ item.name }}
            </div>
          </div>
          <div v-if="!addressList.length && ifChangeArea"
               class="text-center c-999 fs14 pt10">
            无下级地址
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { defineComponent, reactive, toRefs, getCurrentInstance, defineExpose, onMounted } from 'vue'
  import { getAreaList } from '@/api/shop'
  export default defineComponent({
    created() {
      let app = getCurrentInstance();
      app.proxy.getAreaListFn();
    },
    setup(props, { emit, expose }) {
      const state = reactive({
        show: false,
        ifClickCityName: false,
        ifChangeArea: false,
        selectAddressNameList: [],
        addressList: [],
        pages: {
          level: 1,
          pid: 0
        }
      });
      const openFn = (value) => {
        state.show = value;
      }
      const getAreaListFn = async () => {
        let rs = await getAreaList(state.pages);
        if (rs.data.length > 0) {
          state.addressList = rs.data;
        } else {
          state.show = false;
          state.ifChangeArea = true;
          emit("selectCity", state.selectAddressNameList);
        }
      }
      const selectCity = (item, index) => {
        if (state.selectAddressNameList > 3) return false;
        state.selectAddressNameList.push(item);
        let length = state.selectAddressNameList.length;
        state.ifChangeArea = false;
        state.addressList = [];
        if (length == 1) {
          state.pages = { level: 2, pid: item.id }
          getAreaListFn();
        } else if (length == 2) {
          state.pages = { level: 3, pid: item.id }
          getAreaListFn();
        } else if (length == 3) {
          state.pages = { level: 4, pid: item.id }
          getAreaListFn();
        } else if (length == 4) {
          state.show = false;
          state.ifChangeArea = true;
          emit("selectCity", state.selectAddressNameList);
        }
      }
      //改变地址
      const changeAddress = (item, index, id) => {
        state.selectAddressNameList = state.selectAddressNameList.splice(0, index);
        state.ifChangeArea = false;
        if (index == 0) {
          state.pages = { level: 1, pid: 0 }
          getAreaListFn();
        } else if (index == 1) {
          state.pages = { level: 2, pid: id }
          getAreaListFn();
        } else if (index == 2) {
          state.pages = { level: 3, pid: id }
          getAreaListFn();
        } else if (index == 3) {
          state.pages = { level: 3, pid: id }
          getAreaListFn();
        }
      }
      expose({
        openFn,
      })
      return {
        getAreaListFn,
        openFn,
        changeAddress,
        selectCity,
        ...toRefs(state)
      }
    }
  })
</script>
<style lang="scss" scoped>
  .jd-city-wrap {
    .van-popup--round {
      border-radius: 6px 6px 0px 0px;
    }
    .van-popup__close-icon--top-right {
      top: 22px;
      right: 15px;
      color: rgba(51, 51, 3, 0.15);
    }
  }
</style>
<style lang="scss" scoped>
  .jd-city-wrap {
    .city-name-box {
      max-width: 68px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .city-list-box {
      height: 340px;
      padding: 10px 15px;
      overflow-y: auto;
      .city-item-box {
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .select-text {
      color: #fe6507;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -21px;
        height: 1px;
        background: #fe6507;
      }
    }
  }
</style>