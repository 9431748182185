<template>
  <div class="editAddress bgc-F7F7F7">
    <navbarTools :leftArrow="true"
                 :fixed="true"></navbarTools>
    <div class="bgc-F7F7F7 jd-add-ship-address-wrap">
      <div class="pt10">
        <div class="bgc-fff pl15 pr15">
          <van-field label="收货人"
                     v-model="ruleForm.name"
                     placeholder="请填写收货人姓名"
                     maxlength="8"
                     class="address-field"
                     label-class="address-field-label" />
          <van-field v-model="ruleForm.mobile"
                     type="tel"
                     label="联系电话"
                     placeholder="请填写收货人手机号码"
                     class="address-field"
                     label-class="address-field-label"
                     maxlength="11" />
          <van-field v-model="areaText"
                     label="所在地区"
                     placeholder="省市区县、乡镇等"
                     :readonly="true"
                     right-icon="arrow"
                     class="address-field"
                     label-class="address-field-label"
                     @click="cityFn" />
          <van-field label="详细地址"
                     v-model="ruleForm.address"
                     placeholder="街道、楼牌号等"
                     maxlength="30"
                     class="address-field"
                     label-class="address-field-label"
                     type="textarea"
                     autosize />
        </div>
      </div>
      <div class="h-60 iphonex-bottom"></div>
      <div class="jd-btn-fixed-bottom iphonex-bottom">
        <div class="radius3 bgc-FE6507 line-height-40 fs16 c-fff text-center mt5"
             @click="sureFn">
          保存
        </div>
      </div>
    </div>
    <!-- 城市选择 -->
    <jd-city ref="jdCity"
             @selectCity="selectCityFn"></jd-city>
  </div>
</template>
<script>
  import navbarTools from '@/components/navbarTools'
  import { toRefs, defineComponent, reactive, ref } from "vue";
  import jdCity from "@/components/shop/jdCity";
  import { doAddressSave, getAddressDetail } from '@/api/shop'
  import { useRouter, useRoute } from 'vue-router'
  export default defineComponent({
    components: {
      navbarTools,
      jdCity
    },
    setup() {
      const $router = useRouter();
      const $route = useRoute();
      const jdCity = ref(null);
      const state = reactive({
        OSS_URL: process.env.VUE_APP_OSS_URL,
        title: "",
        type: $route.query.type,
        areaText: "",
        ruleForm: {
          address: null,
          addressId: 0,
          areaId: 0,
          cityId: 0,
          mobile: null,
          name: null,
          provinceId: 0,
          townId: 0
        },
      });

      const sureFn = async () => {
        let rs = await doAddressSave(state.ruleForm);
        if (rs.code === 0) {
          $router.replace({
            path: '/address/addressList'
          })
        }
      }
      const cityFn = () => {
        jdCity.value.openFn(true);
      }
      const selectCityFn = async (options) => {
        state.ruleForm.provinceId = options[0].id
        state.ruleForm.cityId = options[1].id
        state.ruleForm.areaId = options[2].id
        state.ruleForm.townId = options[3]?.id ?? 0
        state.areaText = `${options[0].name}${options[1].name}${options[2].name}${options[3]?.name ?? ''}`
      }
      const getAddressDetailFn = async () => {
        if ($route.query.type == 'edit') {
          $route.meta.title = "编辑收货地址"
          let rs = await getAddressDetail({ addressId: $route.query.addressId });
          if (rs.code === 0) {
            let obj = rs.data;
            state.areaText = `${obj.provinceName}${obj.cityName}${obj.areaName}${obj.townName}`
            state.ruleForm = {
              address: obj.address,
              addressId: obj.userAddressId,
              areaId: obj.areaId,
              cityId: obj.cityId,
              mobile: obj.mobile,
              name: obj.name,
              provinceId: obj.provinceId,
              townId: obj.townId
            }
          }
        }
      }
      getAddressDetailFn();
      return {
        ...toRefs(state),
        sureFn,
        jdCity,
        cityFn,
        selectCityFn
      }
    }
  });
</script>
<style style lang="scss" scoped>
  .editAddress {
    height: 100%;
    ::v-deep(.navbar) {
      width: 375px;
      .van-nav-bar {
        .van-nav-bar__left .van-icon {
          font-size: 24px;
          color: #000;
        }
        .van-nav-bar__title {
          font-size: 18px;
          font-weight: 600;
        }
        .van-nav-bar__right .van-nav-bar__text {
          color: #333;
        }
      }
    }
    .jd-add-ship-address-wrap {
      position: relative;
      top: 46px;
      .address-field {
        padding: 14px 0;
        .address-field-label {
          color: #333333;
          width: 75px;
        }
        .van-cell__value {
          color: #999;
          .van-field__control {
            color: #333333;
          }
        }
      }
      .van-cell::after {
        border-bottom-color: rgba($color: #000000, $alpha: 0.05);
        left: 0;
      }
    }
  }
</style>
 
